import React from "react";
import { Link } from "react-router-dom";
import RoundImage from "../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../helpers/constant";
import { fromNow } from "../../helpers/dates";

const Notification = (props) => {
    const { notification: n } = props;

    return (
        <>
            <div className="card my-1 p-2 bg-light border-light rounded">
                <div className="row m-0 d-flex align-items-center bd-highlight flex-nowrap ">
                    <div className={`text-center mr-2 `}>
                        <RoundImage className="img-thumbnail" src={n?.image || PLACEHOLDER} size="50px" />
                    </div>
                    <div className="detail-sec">
                        <Link to={n?.link}>
                            <p className="mb-0">{n?.description}</p>
                        </Link>
                        <small className="d-block text-muted">{fromNow(n?.created_at)}</small>
                    </div>
                </div>
            </div>
        </>
        // <div>
        //     <figure>
        //         {" "}
        //         <RoundImage src={n?.image || PLACEHOLDER} alt="n.title" size="35px" />
        //     </figure>
        //     <div className="mesg-info">
        //         <Link to={n?.link}>
        //             <p style={{ marginBottom: "0", fontSize: "12px" }}>{n?.description}</p>
        //         </Link>
        //         <small className="text-info">{fromNow(n?.created_at)}</small>
        //     </div>
        // </div>
    );
};

export default Notification;
