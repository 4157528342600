import React, { useEffect, useState, useCallback, useMemo } from "react";
import ThemeLayout from "../../layout/themeLayout";
import { PLACEHOLDER } from "helpers/constant";
import styles from "./listing.module.css";
import { Link, useHistory, useParams } from "react-router-dom";
import RoundImage from "../../component/Image/RoundImage";
import Button from "../../component/Button/Button";
import { hunts as huntsApi } from "../../api/hunts";
import { challenges as challengesApi } from "../../api/challenges";
import { users as usersApi } from "../../api/users";
import { notifications as notificationsApi } from "../../api/notifications";
import { useDispatch } from "react-redux";
import { Hunt } from "../../Widgets/Listing/Hunt";
import Notification from "../../Widgets/Listing/Notification";
import { useRef } from "react";
import Spinner from "../../component/Spinner/Spinner";
import InfiniteScroll from "react-infinite-scroller";
import { User } from "../../Widgets/Listing/User";
import { Challenge } from "../../Widgets/Listing/Challenge";
import { posts as postsApi } from "../../api/posts";
import { clubs as clubsApi } from "../../api/clubs";
import { Club } from "../../Widgets/Listing/Club";
import ClubMember from "../../Widgets/Listing/ClubMember";
import BackButton from "../../component/BackButton/BackButton";
import Icon from "../../component/Icon/Icon";
import Applicant from "../../Widgets/Listing/Applicant";
import { Boost } from "../../Widgets/Listing/Boost";

const Listing = React.memo((props) => {
    const { type, id } = useParams();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const paginationData = useRef({});
    const currentPage = useRef(0);
    const response = useRef({});
    const renderListItem = useRef({});

    const title = useRef(null);
    const icon = useRef(null);
    const additional = useRef({});

    const getData = useCallback(async () => {
        try {
            const params = {
                isBackground: false,
                params: {
                    page_no: currentPage.current + 1,
                },
            };
            switch (type) {
                case "hunts":
                    response.current = await dispatch(huntsApi(params));

                    break;
                case "users":
                    response.current = await dispatch(usersApi(params));

                    break;

                case "challenges":
                    response.current = await dispatch(challengesApi({ ...params, param_path: "active" }));
                    break;
                case "winner":
                case "runnerup":
                    response.current = await dispatch(challengesApi({ ...params, param_path: `${type}/${id}` }));
                    break;

                case "boosts":
                    response.current = await dispatch(
                        postsApi({
                            ...params,
                            param_path: `${id}/${type}`,
                        })
                    );
                    break;

                case "followers":
                    response.current = await dispatch(
                        usersApi({
                            ...params,
                            param_path: `${id}/followers`,
                        })
                    );

                    break;

                case "following":
                    response.current = await dispatch(
                        usersApi({
                            ...params,
                            param_path: `${id}/following`,
                        })
                    );

                    break;

                case "subscribers":
                    response.current = await dispatch(
                        usersApi({
                            ...params,
                            param_path: `${id}/subscribers`,
                        })
                    );

                    break;

                case "notifications":
                    response.current = await dispatch(
                        notificationsApi({
                            ...params,
                        })
                    );
                    break;

                case "likes":
                    response.current = await dispatch(
                        postsApi({
                            ...params,
                            param_path: `${id}/likes`,
                        })
                    );
                    break;

                case "views":
                    response.current = await dispatch(
                        postsApi({
                            ...params,
                            param_path: `${id}/views`,
                        })
                    );
                    break;

                case "joined-clubs":
                    response.current = await dispatch(
                        usersApi({
                            ...params,
                            param_path: `joined-clubs`,
                        })
                    );
                    break;

                case "my-clubs":
                    response.current = await dispatch(
                        clubsApi({
                            ...params,
                            param_path: `my-clubs`,
                        })
                    );
                    break;

                case "members-of":
                    params.params.status = "active";

                    response.current = await dispatch(
                        clubsApi({
                            ...params,
                            param_path: `${id}/members`,
                        })
                    );
                    title.current = "Members of " + response.current.additional.title;
                    break;

                case "manage-members":
                    params.params.status = "active";

                    response.current = await dispatch(
                        clubsApi({
                            ...params,
                            param_path: `${id}/members`,
                        })
                    );
                    title.current = "Members of " + response.current.additional.title;
                    break;

                case "member-requests":
                    params.params.status = "pending";

                    response.current = await dispatch(
                        clubsApi({
                            ...params,
                            param_path: `${id}/members`,
                        })
                    );
                    title.current = "Membership Requests for " + response.current.additional.title;
                    break;

                case "shortlisted":
                    params.params.status = "shortlisted";

                    response.current = await dispatch(
                        huntsApi({
                            ...params,
                            param_path: `${id}/applicants/${type}`,
                        })
                    );

                    title.current = "Users shortlisted for " + response.current.additional;
                    break;

                case "interested":
                    params.params.status = "interested";

                    response.current = await dispatch(
                        huntsApi({
                            ...params,
                            param_path: `${id}/applicants/${type}`,
                        })
                    );

                    title.current = "Applicants for " + response.current.additional;
                    break;
                default:
                    break;
            }

            paginationData.current = response.current?.pagination_data;
            currentPage.current = paginationData.current?.current_page;
            additional.current = response.current?.additional;

            if (response.current?.success) {
                setData((p) => (currentPage.current > 1 ? p.concat(response.current?.data) : response.current?.data));
            }
        } catch (e) {
            console.log(e);
        }
    }, [dispatch]);

    useEffect(() => {
        getData();
    }, [getData]);

    const renderLists = useMemo(() => {
        switch (type) {
            case "hunts":
                icon.current = "briefcase text-danger";
                renderListItem.current = () => data?.map((h, i) => <Hunt key={i} hunt={h} />);
                break;
            case "users":
                icon.current = "users text-info";
                renderListItem.current = () => data?.map((u, i) => <User key={i} user={u} />);
                break;
            case "challenges":
                icon.current = "trophy text-warning";
                renderListItem.current = () => data?.map((c, i) => <Challenge key={i} challenge={c} />);
                break;
            case "winner":
                title.current = `Challenges where ${additional?.current?.user?.name} is a Winner`;
                icon.current = "trophy text-warning";
                renderListItem.current = () => data?.map((c, i) => <Challenge key={i} challenge={c} />);
                break;
            case "boosts":
                title.current = `Boost History`;
                icon.current = "trophy text-warning";
                renderListItem.current = () => data?.map((b, i) => <Boost key={i} boost={b} />);
                break;
            case "runnerup":
                title.current = `Challenges where ${additional?.current?.user?.name} is a Runnerup`;
                icon.current = "trophy text-warning";
                renderListItem.current = () => data?.map((c, i) => <Challenge key={i} challenge={c} />);
                break;
            case "followers":
                title.current = `Users Following ${additional?.current?.user?.name}`;
                renderListItem.current = () => data?.map((u, i) => <User key={i} user={u} />);
                break;
            case "subscribers":
                title.current = `Subscribers of ${additional?.current?.user?.name}`;
                renderListItem.current = () => data?.map((u, i) => <User key={i} user={u} />);
                break;
            case "following":
                title.current = `Users Followed by ${additional?.current?.user?.name}`;
                icon.current = "users text-info";
                renderListItem.current = () => data?.map((u, i) => <User key={i} user={u} />);
                break;
            case "notifications":
                icon.current = "users text-info";
                renderListItem.current = () => data?.map((n, i) => <Notification key={i} notification={n} />);
                break;
            case "likes":
                icon.current = "like text-info";
                renderListItem.current = () => data?.map((u, i) => <User key={i} user={u} />);
                break;
            case "views":
                renderListItem.current = () => data?.map((u, i) => <User key={i} user={u} />);
                break;
            case "joined-clubs":
                icon.current = "users-social text-success";
                title.current = "Joined Clubs";
                renderListItem.current = () => data?.map((c, i) => <Club key={i} club={c} />);
                break;
            case "my-clubs":
                title.current = "My Clubs";
                icon.current = "users-social text-success";
                renderListItem.current = () => data?.map((c, i) => <Club key={i} club={c} />);
                break;

            case "members-of":
                title.current = "Club Members";
                renderListItem.current = () => data?.map((u, i) => <User key={i} user={u} />);
                break;
            case "manage-members":
                title.current = "Club Members";
                icon.current = "users text-info";
                renderListItem.current = () =>
                    data?.map((u, i) => (
                        <ClubMember
                            key={i}
                            user={u}
                            clubId={id}
                            deleteUser={(id) => {
                                setData(data.filter((r) => r.id !== id));
                            }}
                        />
                    ));
                break;
            case "member-requests":
                title.current = "Membership Requests";
                icon.current = "users text-info";
                renderListItem.current = () =>
                    data?.map((u, i) => (
                        <ClubMember
                            key={i}
                            user={u}
                            clubId={id}
                            deleteUser={(id) => {
                                setData(data.filter((r) => r.id !== id));
                            }}
                        />
                    ));
                break;

            case "shortlisted":
                icon.current = "users text-info";
                renderListItem.current = () => data?.map((u, i) => <Applicant key={i} user={u.user} huntId={id} applicationStatus={u.application_status} />);
                break;

            case "interested":
                icon.current = "users text-info";
                renderListItem.current = () => data?.map((u, i) => <Applicant key={i} user={u.user} huntId={id} applicationStatus={u.application_status} />);
                break;

            default:
                break;
        }
        const onEndReach = () => {
            getData();
        };

        return (
            <InfiniteScroll pageStart={0} loadMore={onEndReach} hasMore={paginationData.current?.current_page < paginationData.current?.last_page} loader={<Spinner key="Loadmore" type="LoadMore" />} threshold={50} useWindow={true}>
                {renderListItem.current()}
            </InfiniteScroll>
        );
    }, [getData, data]);

    return (
        <ThemeLayout>
            <section>
                <div className="gap">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-8">
                                <div className="my-3">
                                    <BackButton />
                                </div>
                                <div className="card shadow border-0">
                                    <div className="card-header p-3 text-capitalize d-flex flex-wrap">
                                        {icon.current && <Icon name={icon.current} className="mr-2 cursor-pointer" style={{ fontSize: "25px" }} />}
                                        {!title.current && <h4 className="d-inline-block">All {type}</h4>}
                                        {title.current && <h4 className="d-inline-block">{title.current}</h4>}
                                    </div>
                                    <div className={`${styles["card-body"]} card-body  p-3`}>{renderLists}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ThemeLayout>
    );
});

export default Listing;
