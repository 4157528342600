import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Icon from "../Icon/Icon";
import { clubs as clubApi } from "../../api/clubs";
import { useDispatch } from "react-redux";

const StatusPillDropDown = (props) => {
    const [showDD, setDD] = useState(props.active);
    const [status, setStatus] = useState(props.status);
    const [options, setOptions] = useState([]);
    const [requestedStatus, setRequestedStatus] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const { userId, clubId } = props;
    const [selectedOption, setSelectedOption] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");
    const dispatch = useDispatch();

    const changeStatus = () => {
        dispatch(
            clubApi({
                param_path: clubId + "/change_status/" + userId,
                method: "POST",
                data: {
                    status: requestedStatus,
                },
            }),
        )
            .then((response) => {
                setShowConfirm(false);
                // setDD(false);
                // props.onSelect(null);
                if (response.success) {
                    setStatus(response.data.status);
                    if (response.data.status == "remove" || response.data.status == "reject") {
                        props.onDelete(userId);
                    }
                }
            })
            .catch((err) => {
                setShowConfirm(false);
                setDD(false);
            });
    };

    useEffect(() => {
        switch (status) {
            case "active":
                setOptions([
                    { val: "active", show: "n" },
                    { val: "remove", show: "y" },
                    { val: "block", show: "y" },
                ]);
                break;
            case "pending":
                setOptions([
                    { val: "pending", show: "n" },
                    { val: "accept", show: "y" },
                    { val: "reject", show: "y" },
                ]);
                break;
            case "blocked":
                setOptions([
                    { val: "blocked", show: "n" },
                    { val: "unblock", show: "y" },
                ]);
                break;

            default:
                setOptions([]);
                break;
        }
    }, [status]);

    useEffect(() => {
        switch (requestedStatus) {
            case "accept":
                changeStatus();
                break;

            case "reject":
                setConfirmMessage("Are you sure you want to reject this user?");
                setShowConfirm(true);
                break;

            case "remove":
                setConfirmMessage("Are you sure you want to remove this user?");
                setShowConfirm(true);
                break;

            case "block":
                setConfirmMessage("Are you sure you want to block this user?");
                setShowConfirm(true);
                break;

            case "unblock":
                setConfirmMessage("Are you sure you want to unblock this user?");
                setShowConfirm(true);
                break;

            default:
                break;
        }
    }, [requestedStatus]);

    return (
        <>
            <ConfirmModal showModal={showConfirm} title="Confirm" message={confirmMessage} onConfirm={() => changeStatus()} onClose={() => setShowConfirm(false)} />

            <div className="btn-group">
                <select className="btn btn-info btn-sm dropdown-toggle badge text-capitalize" value={status} onChange={(e) => setRequestedStatus(e.target.value)}>
                    {options.map((o, i) => (
                        <option
                            key={i}
                            className={`bg-light text-dark text-capitalize cursor-pointer${o.show == "y" ? " d-block" : " d-none"}`}
                            onClick={() => {
                                setRequestedStatus(o.val);
                            }}
                            value={o.val}
                        >
                            {o.val}
                        </option>
                    ))}
                </select>

                {/* {options.length > 0 && (
                    <div className={`dropdown-menu ${props.active ? " show" : ""}`} onBlur={() => setDD(false)}>
                        {options.map((o, i) => (
                            <span
                                key={i}
                                className="dropdown-item text-capitalize cursor-pointer"
                                onClick={() => {
                                    setRequestedStatus(o);
                                }}
                            >
                                {o}
                            </span>
                        ))}
                    </div>
                )} */}
            </div>
        </>
    );
};

export default StatusPillDropDown;
