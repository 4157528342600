import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../component/Button/Button";
import RoundImage from "../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../helpers/constant";
import { clubs as clubApi } from "../../api/clubs";
import React from "react";
import ConfirmModal from "../../component/ConfirmModal/ConfirmModal";
import StatusPillDropDown from "../../component/ActionDropdown/StatusPillDropDown";

const ClubMember = (props) => {
    const [u, setUser] = useState(props.user);
    const clubId = props.clubId;
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = useState(false);

    const [confirmMessage, setConfirmMessage] = useState("");

    const [status, setStatus] = useState(props.user.status);
    const [member, setMember] = useState();
    const [selectedMember, setSelectedMember] = useState();

    const changeStatus = (user, status) => {
        dispatch(
            clubApi({
                param_path: clubId + "/change_status/" + user?.id,
                method: "POST",
                data: {
                    status,
                },
            }),
        )
            .then((response) => {
                if (response.success) {
                    setUser({ ...u, status: response.data.status });
                    setStatus(response.data.status);
                }
            })
            .catch((err) => { });
    };

    const handleConfirm = (member, action) => {
        setConfirmMessage("Are you sure you want to " + action + " " + member.name + "?");
        setStatus(action == "block" ? "delete" : action);
        setMember(member);
        setShowConfirm(true);
    };

    return (
        <>
            {status !== "remove" ? (
                <div className="card my-1 p-2 bg-light border-light rounded">
                    <ConfirmModal showModal={showConfirm} title="Confirm" message={confirmMessage} onConfirm={() => changeStatus()} onClose={() => setShowConfirm(false)} />
                    <div className="row m-0 d-md-flex text-sm-center align-items-center bd-highlight ">
                        <div className={`col-md-1`}>
                            <RoundImage className="img-thumbnail" src={u.profile_pic || PLACEHOLDER} size="50px" />
                        </div>
                        <div className="col-md-7 detail-sec my-sm-2">
                            <Link to={`/profile/${u.id}`}>
                                <p className="mb-0 text-capitalize text-truncate">{u.name}</p>
                            </Link>
                            <small className="d-block text-muted">{u.intro}</small>
                        </div>
                        <div className="col-md-4 text-sm-center text-right">
                            <StatusPillDropDown
                                status={u.status}
                                userId={u.id}
                                clubId={clubId}
                                active={selectedMember == u.id}
                                onClick={() => setSelectedMember(u.id)}
                                onDelete={(status) => {
                                    setStatus("remove");
                                }}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
};

export default ClubMember;
