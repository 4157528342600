import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import RoundImage from "../../component/Image/RoundImage";
import { PLACEHOLDER } from "../../helpers/constant";

const Applicant = (props) => {
    const { user, applicationType, huntId } = props;
    const history = useHistory();

    return (
        <>
            <div className="card my-1 p-2 bg-light border-light rounded" style={{ cursor: "pointer" }} onClick={() => history.push(`/hunts/${huntId}/${applicationType == "special" ? "cv" : "resume"}/${user.id}`)}>
                <div className="row m-0 d-flex align-items-center bd-highlight ">
                    <div className={`col-md-1`}>
                        <RoundImage className="img-thumbnail" src={user.profile_pic || PLACEHOLDER} size="50px" />
                    </div>
                    <div className="col-md-7 detail-sec">
                        <p className="mb-0 text-capitalize text-truncate">{user.name}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Applicant;
