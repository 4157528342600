import React, { useState, useEffect, useCallback } from "react";
import { PLACEHOLDER } from "helpers/constant";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Button from "../../component/Button/Button";
import RoundImage from "../../component/Image/RoundImage";
import SendNotification from "../../helpers/notification";
import { useHistory } from "react-router-dom";
import styles from "../../pages/Listing/listing.module.css";
import Icon from "../../component/Icon/Icon";
export const Boost = (props) => {
    const item = props.boost;
    const auth_user = useSelector((store) => store.user.user);
    const history = useHistory();

    return (
        <>
            <div className="card my-1 p-2 bg-light border-light rounded">

                <div className="row m-0 d-flex align-items-center bd-highlight flex-sm-column align-items-sm-center justify-content-sm-center text-sm-center">
                    <div className={``}>
                        <RoundImage className="img-thumbnail" src={item.thumbnail || PLACEHOLDER} size="50px" />
                    </div>
                    <div className=" detail-sec flex-fill ml-2 my-sm-1">
                        <Link className="" to={`/post/${item.post_id}`}>
                            <p className="mb-0 text-truncate font-weight-bold text-capitalize">{item.content}</p>
                            {item.status == 'active' && <p style={{
                                margin: '0'
                            }}><span className="badge badge-success">{item.status}</span></p>}
                        </Link>
                        <div className="reach">
                            <small className="mb-0">Reached <Icon className={`text-info mx-1`} name={`users`} />{item.views} users</small>
                        </div>
                        <div className="budget">
                            <small className="d-inline-block" > Budget:<Icon className={`text-info`} name={`dollar`} />{item.budget} | Spend:<Icon className={`text-info`} name={`dollar`} />{item.charges}</small>
                        </div>

                    </div>
                    <div className="d-flex flex-column justify-cotent-center">
                        <div className="actions my-sm-2">
                            <Link className=" btn btn-info btn-sm rounded-pill" to={`/boosts/${item.id}`}> <Icon className={`mr-1`} name={`chart-histogram`} /> Post Insight</Link>
                        </div>
                    </div>

                </div>

            </div>
        </>
    );
};
